import React, { useState } from "react";
import styled from "styled-components";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { Google, Facebook } from "react-bootstrap-icons";
import { Auth } from "aws-amplify";
import { useApolloClient } from "@apollo/client";
import claimProperty from "../Services/claimProperty";
import { useParams } from "react-router-dom";
import logo from "../logotype.svg";

const AUTH_USER_TOKEN_KEY = "PB_TOKEN";

const SocialButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`;

const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  padding: 30px;
  border-radius: 12px;
  margin-top: 30px;
`;

const CenterCol= styled(Col)`
  text-align: center;
`;

const Logo = styled.img``;

const Login = () => {
  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const client = useApolloClient();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    Auth.signIn(username, password)
      .then(async (user) => {
        console.log(user);
        sessionStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          user.signInUserSession.idToken.jwtToken
          
        );

        if (id) {
          await claimProperty(client, user.attributes.sub, id);
        }
        window.location.href = "/dashboard";
      })
      .catch((err) => {
        alert("User sign-in failed");
        console.log(err);
      });
  };

  function signInWithFacebook() {
    //var oauth2Endpoint = "https://www.facebook.com/v14.0/dialog/oauth";
    var oauth2Endpoint = "https://propbook.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize"

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: "50sthnspf3ss9bd1roak73r96u",
      //redirect_uri: "http://localhost:3000/fb_callback.html",
      redirect_uri: "https://propertylogbook.com.au/fb_callback.html",
      response_type: "token",
      scope: "email openid",
      identity_provider: 'Facebook',
      state: '0'
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  function signInWithGoogle() {

    // Google's OAuth 2.0 endpoint for requesting an access token
    // var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    var oauth2Endpoint = "https://propbook.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize"
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: "50sthnspf3ss9bd1roak73r96u",
      //redirect_uri: "https://localhost:3000/oauth_callback.html",
      redirect_uri: "https://propertylogbook.com.au/oauth_callback.html",
      response_type: "token",
      scope: "profile email openid",
      identity_provider: 'Google',
      state: '0'
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }

  return (
    <Container>
      <Row>
          <Col>
          <Card>
            <Card.Body>
              <SocialButton
                style={{ backgroundColor: "red", borderColor: "red" }}
                size="sm"
                onClick={signInWithGoogle}
              >
                <Google size={12} /> Log in with Google
              </SocialButton>
              <br />
              <SocialButton size="sm" onClick={signInWithFacebook}>
                <Facebook size={12} /> Log in with Facebook
              </SocialButton>
            </Card.Body>
          </Card>
          </Col>
        </Row>
        <br/>
        <br/>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <a href="/forgot-password">Forgot password?</a>
          </Col>
          <Col style={{textAlign:'right'}}>
            <Button type="submit">Submit</Button>
          </Col>
        </Row>       
      </Form>
    </Container>
  );
};

export default Login;
