import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation, gql, useApolloClient } from '@apollo/client';
import { Card as BSCard, Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import claimProperty from '../Services/claimProperty';
import PropertyAddress from '../Components/PropertyAddress';
import { error } from 'logrocket';

const Card = styled(BSCard)`
  background-color: transparent;
  border: none;
`;

const LandingContainer = styled.div`
  margin-top: 100px;
  padding: 15px 10px 10px 10px;

  h5 {
    padding: 0 15px;
    font-size: 1rem;
    font-weight: 500;
  }
`;

const CopyHeading = styled.h2`
  font-size: 1.2rem !important;
  font-weight: 500;
  color: #333;
`;

const ADD_PROPERTY = gql`
  mutation InsertPropertyMutation(
    $formattedAddress: String!
    $firstBuilt: String!
    $interest: String!
  ) {
    insert_properties(
      objects: {
        formatted_address: $formattedAddress
        property_profiles: {
          data: { first_built: $firstBuilt, interest: $interest }
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

const Landing = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [propertyAddress, setPropertyAddress] = useState('');
  const [propertyBuildingYear, setPropertyBuildingYear] = useState(0);
  const [propertyInterest, setPropertyInterest] = useState('');

  const [addProperty, addResponse] = useMutation(ADD_PROPERTY, {
    onCompleted: async (data) => {
      if (data?.insert_properties?.returning?.length > 0) {
        console.log('here');
        const propertyId = data?.insert_properties.returning[0].id;
        try {
          const currentUser = await Auth.currentAuthenticatedUser();

          console.log({ currentUser });
          if (currentUser) {
            await claimProperty(client, currentUser.username, propertyId);
          }
          navigate(`/health-score/${propertyId}`);
        } catch (err) {
          navigate(`/health-score/${propertyId}`);
        }
      }
    },
  });

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // setScoreInput({
    //   address: propertyAddress.label,
    //   building_year: parseInt(propertyBuildingYear),
    //   interest_in_property: propertyInterest,
    // });

    addProperty({
      variables: {
        formattedAddress: propertyAddress.label,
        firstBuilt: propertyBuildingYear,
        interest: propertyInterest,
      },
    });
  };

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      //navigate("/dashboard");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    console.log(addResponse);
  }, [addResponse]);

  useEffect(() => {
    console.log(propertyAddress);
  }, [propertyAddress]);

  return (
    <LandingContainer>
      <Row>
        <Col>
          <CopyHeading>Property Health Score</CopyHeading>
          <p>
            Construction quality, materials &amp; method and maintenance levels
            have varying, but consistent impacts on a building's dilapidation
            over time. Using our unique algorithm, Property Lobgook is able to
            take this information and provide a general Property Health Score
            for your asset.
          </p>
          <br />

          <br />
          <CopyHeading>Property Logbook</CopyHeading>
          <p>
            Keep track of your asset with a property logbook, designed to help
            you quickly capture and categorise changes and updates happening to
            your property. Your logbook is connected to your Health Score, so as
            things change, you stay on top of your asset.
          </p>
          <br />

          <br />
          <CopyHeading>Maintenance Checklists</CopyHeading>
          <p>
            Sometimes it is hard and expensive to make sure your property is
            healthy. Use the app's in-built maintenance checklists to keep track
            of elements of the asset that have been looked after, or are in need
            of a bit of attention.
          </p>
        </Col>
        <Col>
          <h5>Understand your Property Health in under 30 seconds!</h5>
          <Card>
            <Form>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Label>Property address</Form.Label>
                    <Form.Group controlId='formAddress'>
                      <PropertyAddress
                        value={propertyAddress}
                        onChange={setPropertyAddress}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId='formHomeBuilt'>
                      <Form.Label>When was the home first built?</Form.Label>
                      <Form.Select
                        aria-label='When was the home first built?'
                        onChange={(evt) =>
                          setPropertyBuildingYear(evt.target.value)
                        }
                      >
                        <option value='0'>Select</option>
                        <option value='2022'>2022</option>
                        <option value='2021'>2021</option>
                        <option value='2020'>2020</option>
                        <option value='2010'>2010-2019</option>
                        <option value='2000'>2000-2009</option>
                        <option value='1990'>1990-1999</option>
                        <option value='1980'>1980-1989</option>
                        <option value='1970'>1970-1979</option>
                        <option value='1960'>1960-1969</option>
                        <option value='1946'>1946-1959 (Post War)</option>
                        <option value='1914'>1914-1945 (War)</option>
                        <option value='1891'>1891-1913 (Federation)</option>
                        <option value='1880'>Pre-1890 (Victorian)</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId='formBasicEmail'>
                      <Form.Label>
                        What is your interest in the property?
                      </Form.Label>
                      <Form.Select
                        aria-label='What is your interest in the property?'
                        onChange={(evt) =>
                          setPropertyInterest(evt.target.value)
                        }
                      >
                        <option>Select</option>
                        <option value='own'>
                          I own and live in this property
                        </option>
                        <option value='rent'>I rent this property</option>
                        <option>I own but do not live in this property</option>
                        <option>I manage this property</option>
                        <option>I want to buy this property</option>
                        <option>I viewed this property</option>
                        <option>I am a neighbour</option>
                        <option>I service this property</option>
                        <option>Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    
                    <Button
                      onClick={handleSubmit}
                      variant='primary'
                      type='submit'
                    >
                      Get my Property Health Score!
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
    </LandingContainer>
  );
};

export default Landing;
