import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import { Auth } from 'aws-amplify';
import { useApolloClient, gql } from '@apollo/client';

const Table = styled.table`
  width: 100%;

  td {
    padding: 10px 0px;
    vertical-align: middle;
  }
`;

const Container = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
`;

const GET_REWARDS = gql`
  query MyQuery($userId: String!) {
    user_rewards(where: { userId: { _eq: $userId } }) {
      id
      electricity_gas
      home_loan
      internet_phone
      insurance
      method_app
      method_email
      method_phone
      method_sms
      r_e_a
      userId
    }
  }
`;

const ADD_REWARDS = gql`
  mutation addRewards($userId: String!) {
    insert_user_rewards(objects: { userId: $userId }) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_REWARDS = gql`
  mutation updateRewards($userId: String!, $electricity_gas: Boolean!) {
    update_user_rewards(
      where: { userId: { _eq: $userId } }
      _set: { electricity_gas: $electricity_gas }
    ) {
      returning {
        id
      }
    }
  }
`;

const Rewards = () => {
  const client = useApolloClient();
  const [rewardsData, setRewardsData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const getCurrentUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    console.log({ user });
    setCurrentUser(user);

    //get rewards and create if needed
    const res = await client.query({
      query: GET_REWARDS,
      variables: { userId: user.username },
    });

    if (res.data?.user_rewards?.length === 0) {
      const insertResponse = await client.mutate({
        mutation: ADD_REWARDS,
        variables: { userId: user.username },
      });
      console.log({ insertResponse });
    } else {
      const {
        electricity_gas,
        home_loan,
        internet_phone,
        insurance,
        method_app,
        method_email,
        method_phone,
        method_sms,
        r_e_a,
      } = res.data.user_rewards[0];
      setRewardsData({
        electricity_gas,
        home_loan,
        internet_phone,
        insurance,
        method_app,
        method_email,
        method_phone,
        method_sms,
        r_e_a,
      });
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleToggle = (evt) => {
    console.log({ evt });
    const r = {...rewardsData}
    r[evt.target.id] =  evt.target.checked;
    setRewardsData(r);

    const updateResponse = client.mutate({mutation: UPDATE_REWARDS, variables: {userId: currentUser.username, electricity_gas: evt.target.checked}})
  };



  return (
    <>
      <h4>Rewards</h4>
      <p>
        Welcome to your rewards! Here you can track your progress towards home
        unlocks and opt into receive better deals on key expenses related to
        your home.
      </p>
      <Container>
        <strong>What offers you are interested in?</strong>
        <br />
        <br />
        <Table>
          <tr>
            <td>Internet and phone</td>
            <td width='50px'>
              {' '}
              <Toggle id='internet_phone' checked={rewardsData?.internet_phone} onChange={handleToggle} />
            </td>
          </tr>
          <tr>
            <td>Electricity and gas</td>
            <td width='50px'>
              {' '}
              <Toggle id='electricity_gas' checked={rewardsData?.electricity_gas} onChange={handleToggle} />
            </td>
          </tr>
          <tr>
            <td>Insurance</td>
            <td width='50px'>
              {' '}
              <Toggle id='insurance' checked={rewardsData?.insurance} onChange={handleToggle} />
            </td>
          </tr>
          <tr>
            <td>Your home loan</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
          <tr>
            <td>Real estate agents</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
        </Table>
      </Container>
      <br />
      <Container>
        <strong>How would you like to receive these offers?</strong>
        <br />
        <br />
        <Table>
          <tr>
            <td>Phone call</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
          <tr>
            <td>SMS</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
          <tr>
            <td>Via this app</td>
            <td width='50px'>
              {' '}
              <Toggle />
            </td>
          </tr>
        </Table>
      </Container>
    </>
  );
};

export default Rewards;
