import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient, gql } from '@apollo/client';
import Select from 'react-select';

const GET_PROPERTIES = gql`
  query MyProperties {
    user_property {
      property {
        id
        formatted_address
      }
    }
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const PropertySelector = ({ onChange }) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState(null);

  const getProperties = async () => {
    const result = await client.query({
      query: GET_PROPERTIES,
      fetchPolicy: 'network-only',
      // variables: { searchQuery },
    });

    if (result.data.user_property.length === 0) {
      navigate('/new-property');
    }

    const propOptions = result.data.user_property.map((prop) => {
      return {
        label: prop.property['formatted_address'],
        value: prop.property.id,
      };
    });
    setPropertyOptions([
      ...propOptions,
      { label: '+ New property', value: 'new' },
    ]);

    const selectedPropertyId = localStorage.getItem('selectedProperty');

    if (selectedPropertyId) {
      const sel = propOptions.find((prop) => prop.value === selectedPropertyId);
      setSelectedProperty(sel);
      onChange(sel);
    } else {
      localStorage.setItem('selectedProperty', propOptions[0]);
      setSelectedProperty(propOptions[0]);
      onChange(propOptions[0]);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  const handleChange = (item) => {
    if (item.value === 'new') {
      navigate('/new-property');
    } else {
      localStorage.setItem('selectedProperty', item.value);
      setSelectedProperty(item);
      if (onChange) onChange(item);
    }
  };

  return (
    <>
      <Label>Active property:</Label>
      <Select
        options={propertyOptions}
        value={selectedProperty}
        onChange={handleChange}
      />
    </>
  );
};

export default PropertySelector;
