import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  padding: 30px;
  border-radius: 12px;
  margin-top: 30px;
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState('REQUEST_CODE');
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPass, setNewPass] = useState('');
  
  const handleForget = async (evt) => {
    evt.preventDefault();

    const res =  await Auth.forgotPassword(username);
    setStage('SUBMIT_NEW')
    
  }

  const handleForgetSubmit = async (evt) => {
    evt.preventDefault();
    const res = await Auth.forgotPasswordSubmit(username , code, newPass);
    console.log(res);
    //navigate("/login");
  
  }



  // const handleSubmit = (evt) => {
  //   evt.preventDefault();

  //   Auth.signIn(username, password)
  //     .then(async (user) => {
  //       console.log(user);
  //       sessionStorage.setItem(
  //         AUTH_USER_TOKEN_KEY,
  //         user.signInUserSession.idToken.jwtToken
  //       );

  //       if (id) {
  //         await claimProperty(client, user.attributes.sub, id);
  //       }
  //       window.location.href = '/dashboard';
  //     })
  //     .catch((err) => {
  //       alert('User sign-in failed');
  //       console.log(err);
  //     });
  // };

  if(stage === 'REQUEST_CODE'){
  return (
    <Container>
      <h5>Forgot password</h5><br/>
      <Form onSubmit={handleForget}>
        <Row>
          <Col>
            <Form.Label>Please enter your email</Form.Label>
            <Form.Control
              type='email'
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>&nbsp; </Col>
          <Col style={{ textAlign: 'right' }}>
            <Button type='submit'>Submit</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

return (
  <Container>
    <p>A verification code has been sent to your email. Please enter it along with a new password below</p>
    <Form onSubmit={handleForgetSubmit}>
      <Row>
        <Col>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            onChange={(evt) => setUsername(evt.target.value)}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Label>Code</Form.Label>
          <Form.Control
            type='text'
            onChange={(evt) => setCode(evt.target.value)}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type='text'
            onChange={(evt) => setNewPass(evt.target.value)}
          /><br/>Add validation
          
        </Col>
      </Row>
      <br />
      <Row>
        <Col>&nbsp; </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button type='submit'>Submit</Button>
        </Col>
      </Row>
    </Form>
  </Container>
);
};

export default ForgotPassword;
